@import "scss/index";
// SASS variables for normal drag handle and bar size.
// Override in your scss file by setting these variables FIRST, then including this file.
$drag-handle-width: 12px !default;
$drag-handle-height: 12px !default;
$drag-bar-size: 1px !default;

// Query to kick us into "mobile" mode with larger drag handles/bars.
// See: https://developer.mozilla.org/en-US/docs/Web/CSS/@media/pointer
$mobile-media-query: '(pointer: coarse)' !default;

// Mobile handle/bar sizes.  Override as above.
$drag-handle-mobile-width: 24px !default;
$drag-handle-mobile-height: 24px !default;

// Handle color/border.
$drag-handle-border: 1px solid $color-rocky !default;
$drag-handle-background-colour: $color-rocky !default;

$half-drag-handle-height: $drag-handle-height / 2;
$half-drag-handle-width: $drag-handle-width / 2;
$half-drag-bar-size: $drag-bar-size / 2;

:global {

  .ReactCrop {
    position: relative;
    display: inline-block;
    cursor: crosshair;
    overflow: hidden;
    // max-width: 100%;
  
    &:focus {
      outline: none;
    }
  
    &--disabled,
    &--locked {
      cursor: inherit;
    }
  
    &__image {
      display: block;
      // max-width: 100%;
      touch-action: none;
    }
  
    &__crop-selection {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate3d(0, 0, 0);
      box-sizing: border-box;
      cursor: move;
      box-shadow: 0 0 0 9999em rgba(255, 255, 255, 0.76);
      touch-action: none;
  
      :global(.ReactCrop--disabled) & {
        cursor: inherit;
      }
  
      :global(.ReactCrop--circular-crop) & {
        border-radius: 50%;
        // box-shadow: 0 0 0 9999em rgba(255, 255, 255, 0.76);
        // box-shadow: 0px 0px 1px 1px white, 0 0 0 9999em rgba(0, 0, 0, 0.5);
      }
  
      // border: 1px solid;
      // border-image-source: url('data:image/gif;base64,R0lGODlhCgAKAJECAAAAAP///////wAAACH/C05FVFNDQVBFMi4wAwEAAAAh/wtYTVAgRGF0YVhNUDw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OEI5RDc5MTFDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OEI5RDc5MTBDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEBQoAAgAsAAAAAAoACgAAAhWEERkn7W3ei7KlagMWF/dKgYeyGAUAIfkEBQoAAgAsAAAAAAoACgAAAg+UYwLJ7RnQm7QmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYgLJHdiinNSAVfOEKoUCACH5BAUKAAIALAAAAAAKAAoAAAIRVISAdusPo3RAzYtjaMIaUQAAIfkEBQoAAgAsAAAAAAoACgAAAg+MDiem7Q8bSLFaG5il6xQAIfkEBQoAAgAsAAAAAAoACgAAAg+UYRLJ7QnQm7SmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYBLJDdiinNSEVfOEKoECACH5BAUKAAIALAAAAAAKAAoAAAIRFISBdusPo3RBzYsjaMIaUQAAOw==');
      // border-image-slice: 1;
      // border-image-repeat: repeat;
    }
    &--invisible-crop &__crop-selection {
      display: none;
    }
  
    &__rule-of-thirds-vt::before,
    &__rule-of-thirds-vt::after,
    &__rule-of-thirds-hz::before,
    &__rule-of-thirds-hz::after {
      content: '';
      display: block;
      position: absolute;
      background-color: rgba(255, 255, 255, 0.4);
    }
  
    &__rule-of-thirds-vt {
      &::before,
      &::after {
        width: 1px;
        height: 100%;
      }
  
      &::before {
        left: 33.3333%;
        left: calc(100% / 3);
      }
  
      &::after {
        left: 66.6666%;
        left: calc(100% / 3 * 2);
      }
    }
  
    &__rule-of-thirds-hz {
      &::before,
      &::after {
        width: 100%;
        height: 1px;
      }
  
      &::before {
        top: 33.3333%;
        top: calc(100% / 3);
      }
  
      &::after {
        top: 66.6666%;
        top: calc(100% / 3 * 2);
      }
    }
  
    &__drag-handle {
      position: absolute;
  
      &::after {
        position: absolute;
        content: '';
        display: block;
        width: $drag-handle-width;
        height: $drag-handle-height;
        background-color: $drag-handle-background-colour;
        border: $drag-handle-border;
        box-sizing: border-box;
  
        // This stops the borders disappearing when keyboard
        // nudging.
        outline: 1px solid transparent;
      }
    }
  
    .ord-nw {
      top: 0;
      left: 0;
      margin-top: -$half-drag-handle-height;
      margin-left: -$half-drag-handle-width;
      cursor: nw-resize;
  
      &::after {
        top: 0;
        left: 0;
      }
    }
    .ord-n {
      top: 0;
      left: 50%;
      margin-top: -$half-drag-handle-height;
      margin-left: -$half-drag-handle-width;
      cursor: n-resize;
  
      &::after {
        top: 0;
      }
    }
    .ord-ne {
      top: 0;
      right: 0;
      margin-top: -$half-drag-handle-height;
      margin-right: -$half-drag-handle-width;
      cursor: ne-resize;
  
      &::after {
        top: 0;
        right: 0;
      }
    }
    .ord-e {
      top: 50%;
      right: 0;
      margin-top: -$half-drag-handle-height;
      margin-right: -$half-drag-handle-width;
      cursor: e-resize;
  
      &::after {
        right: 0;
      }
    }
    .ord-se {
      bottom: 0;
      right: 0;
      margin-bottom: -$half-drag-handle-height;
      margin-right: -$half-drag-handle-width;
      cursor: se-resize;
  
      &::after {
        bottom: 0;
        right: 0;
      }
    }
    .ord-s {
      bottom: 0;
      left: 50%;
      margin-bottom: -$half-drag-handle-height;
      margin-left: -$half-drag-handle-width;
      cursor: s-resize;
  
      &::after {
        bottom: 0;
      }
    }
    .ord-sw {
      bottom: 0;
      left: 0;
      margin-bottom: -$half-drag-handle-height;
      margin-left: -$half-drag-handle-width;
      cursor: sw-resize;
  
      &::after {
        bottom: 0;
        left: 0;
      }
    }
    .ord-w {
      top: 50%;
      left: 0;
      margin-top: -$half-drag-handle-height;
      margin-left: -$half-drag-handle-width;
      cursor: w-resize;
  
      &::after {
        left: 0;
      }
    }
  
    // Use the same specificity as the ords above but just
    // come after.
    &__disabled &__drag-handle {
      cursor: inherit;
    }
  
    &__drag-bar {
      position: absolute;
      background: $color-rocky;
  
      &.ord-n {
        top: 0;
        left: 0;
        width: 100%;
        height: $drag-bar-size;
        margin-top: -$half-drag-bar-size;
      }
      &.ord-e {
        right: 0;
        top: 0;
        width: $drag-bar-size;
        height: 100%;
        margin-right: -$half-drag-bar-size;
      }
      &.ord-s {
        bottom: 0;
        left: 0;
        width: 100%;
        height: $drag-bar-size;
        margin-bottom: -$half-drag-bar-size;
      }
      &.ord-w {
        top: 0;
        left: 0;
        width: $drag-bar-size;
        height: 100%;
        margin-left: -$half-drag-bar-size;
      }
    }
  
    // &--new-crop &__drag-bar,
    // &--new-crop &__drag-handle,
    // &--fixed-aspect &__drag-bar {
    //   display: none;
    // }
  
    &--fixed-aspect &__drag-handle.ord-n,
    &--fixed-aspect &__drag-handle.ord-e,
    &--fixed-aspect &__drag-handle.ord-s,
    &--fixed-aspect &__drag-handle.ord-w {
      display: none;
    }
  
    @media #{$mobile-media-query} {
      .ord-n,
      .ord-e,
      .ord-s,
      .ord-w {
        display: none;
      }
  
      &__drag-handle {
        width: $drag-handle-mobile-width;
        height: $drag-handle-mobile-height;
      }
    }
  }
}