$shadow-basic: #00000026 0 2px 6px;
$big-shadow-titanic-1: 0 9px 28px rgba(17, 48, 121, 0.18);
$small-shadow-titanic: 0 2px 8px rgba(4, 21, 62, 0.12);
$medium-shadow-titanic: 0 4px 16px rgba(4, 21, 62, 0.16);
$shadow-white: -20px 0 21px 14px rgba(255, 255, 255, 1.0);

@mixin basic-drop-shadow {
  box-shadow: $shadow-basic;
}

@mixin small-shadow-titanic {
  box-shadow: $small-shadow-titanic;
}

@mixin big-shadow-titanic-1 {
  box-shadow: $big-shadow-titanic-1;
}

@mixin medium-shadow-titanic {
  box-shadow: $medium-shadow-titanic;
}

@mixin shadow-white {
  box-shadow: $shadow-white;
}

.shadow-medium-titanic {
  box-shadow: $medium-shadow-titanic;
}

.shadow-white {
  box-shadow: $shadow-white;
}

.shadow-medium-titanic-hover {

  &:hover {
    box-shadow: $medium-shadow-titanic;
  }
}

.shadow-titanic-1 {
  box-shadow: $big-shadow-titanic-1;
}

.gradient-titanic-80 {
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: linear-gradient(360deg, rgba($color-titanic, 0.8) 0%, rgba($color-titanic, 0) 100%);
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
}