/* Colors
 * https://wiki.rusatom.dev/pages/viewpage.action?pageId=335562&preview=/335562/11534371/%D0%A0%D1%83%D0%BA%D0%BE%D0%B2%D0%BE%D0%B4%D1%81%D1%82%D0%B2%D0%BE%20%D0%BF%D0%BE%20%D1%81%D1%82%D0%B8%D0%BB%D1%8E%20%D1%86%D0%B8%D1%84%D1%80%D0%BE%D0%B2%D1%8B%D1%85%20%D0%BF%D1%80%D0%BE%D0%B4%D1%83%D0%BA%D1%82%D0%BE%D0%B2%2020201224.pdf
 */

// Blue
$color-titanic: #04153e;
$color-gladiator: #091f52;
$color-interstellar: #113079;
$color-godfather: #214eb0;
$color-rocky: #3d75e4;
$color-alien: #81abee;
$color-memento: #c0d6f6;
$color-arrival: #e4edfb;
$color-rush: #f6f9fe;

// Gray
$color-bladerunner: #111827;
$color-harakiri: #1c2331;
$color-vertigo: #2e3648;
$color-troy: #495469;
$color-metropolis: #6f7a90;
$color-pianist: #a2abbe;
$color-inception: #cdd3df;
$color-apocalypse: #e6ebf5;
$color-trainspotting: #f5f8fc;

// Purple
$color-extraction: #21043e;
$color-room: #300f52;
$color-heat: #4a197b;
$color-casino: #6d3aa6;
$color-gentlemen: #9954f2;
$color-seven: #bb9af4;
$color-unforgiven: #dbccfa;
$color-departed: #efe8fc;
$color-intouchables: #f9f6fe;

// Red
$color-prestige: #34040f;
$color-exorcist: #480a17;
$color-predator: #711426;
$color-stalker: #ab213a;
$color-fargo: #e14761;
$color-indiana: #f196a5;
$color-bandit: #f7cfd5;
$color-romance: #fbe9ec;
$color-joker: #fef8f9;

// Green
$color-oldboy: #142202;
$color-forrest: #1f3404;
$color-whiplash: #304d0a;
$color-shining: #467510;
$color-matrix: #57a003;
$color-drive: #8dc95e;
$color-psycho: #c7e6a8;
$color-amadeus: #e3fed8;
$color-shawshank: #f6fcf3;

// Brown
$color-schindler: #2a1704;
$color-capernaum: #3a1f03;
$color-platoon: #643302;
$color-mulholland: #9e4f00;
$color-lebowski: #d06e0b;
$color-snatch: #e9a35d;
$color-chinatown: #f8d6b5;
$color-jaws: #fbecdd;
$color-warrior: #fefaf6;

// Socials
$color-yandex_yellow: #ffcc00;
$color-mailru_gold: #ffa520;
$color-odnoklassniki: #ee8208;
$color-googleplus: #db4437;
$color-yandex_red: #ff0000;
$color-instagram: #c13584;
$color-facebook: #3b5998;
$color-mailru_blue: #115ff9;
$color-vkontakte: #2787f5;
$color-twitter: #4ea0eb;

// Partners
$color-gosuslugi_red: #ed2d52;
$color-gosuslugi_blue: #0266b1;

// Banks
$color-citibank: #003b70;
$color-gazprombank: #02356c;
$color-vtb24: #0a2972;
$color-uralsib: #1e398d;
$color-psbank: #2647a3;
$color-tinkoff: #3b5998;
$color-paypal: #0070ba;
$color-otkrytie: #00bbe4;
$color-sberbank: #1a9f29;
$color-mkb: #b3002d;
$color-unicredit: #e30613;
$color-rosbank: #e7002a;
$color-alfabank: #f03226;
$color-raifeizen: #fff104;

// White
$color-white: #fff;
$color-amelie: #fff;
$color-white-70: rgba(#fff, .7);
$color-white-64: rgba(#fff, .64);
$color-rocky-08: rgba(#3d75e4, .08);

// Black
$color-black: #000;
$color-terminator: #000;
