$ease-in-out-quadratic: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$ease-out-quadratic: cubic-bezier(0.25, 0.46, 0.45, 0.94);


@mixin basic-transition($property: all) {
  transition: $property 0.23s $ease-in-out-quadratic;
}

.ease-transition {
  transition: all ease 0.23s;
}

.linear-transition-05 {
  transition: all linear 0.2s;
}
